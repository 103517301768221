import { lazy } from "react";

import { ROLES } from "../Utils/roleAndPermUtils";
import i18n from "../i18n";
import * as myRouters from "../Utils/routerConstants";

const BackendLayout = lazy(() => import("../Backend/Layout/BackendLayout"));
const DashboardPage = lazy(() =>
  import("../Backend/Pages/Dashboard/DashboardPage")
);
const ProfilePage = lazy(() => import("../Backend/Pages/Profile/ProfilePage"));
const ChangePasswordPage = lazy(() =>
  import("../Backend/Pages/Account/ChangePassword/ChangePasswordPage")
);
const HistoryLogPage = lazy(() =>
  import("../Backend/Pages/HistoryLog/HistoryLogPage")
);

const NotAuthorizationPage = lazy(() =>
  import("../Backend/Pages/NotAuthorization/NotAuthorizationPage")
);
const WelcomePage = lazy(() =>
  import("../Backend/Pages/NotAuthorization/WelcomePage")
);
// const PaymentHistoryPage = lazy(() =>
//   import("../Backend/Pages/Payment/PaymentHistoryPage")
// );
// // ========================

// BLOG
const BlogDashboardPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogDashboardPage")
);
const BlogCategoryListPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogCategory/BlogCategoryListPage")
);
const BlogPostListPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogPost/BlogPostListPage")
);
const BlogPostEditPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogPost/BlogPostEditPage")
);
const BlogPostListByCategoryPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogPost/BlogPostListByCategoryPage")
);
const BlogPostViewMostPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogPost/BlogPostViewMostPage")
);
const BlogPostCommentListPage = lazy(() =>
  import("../Backend/Pages/Blog/BlogPostComment/BlogPostCommentListPage")
);

// NEWS
const NewsDashboardPage = lazy(() =>
  import("../Backend/Pages/News/NewsDashboardPage")
);
const NewsCategoryListPage = lazy(() =>
  import("../Backend/Pages/News/NewsCategory/NewsCategoryListPage")
);
const NewsPostListPage = lazy(() =>
  import("../Backend/Pages/News/NewsPost/NewsPostListPage")
);
const NewsPostEditPage = lazy(() =>
  import("../Backend/Pages/News/NewsPost/NewsPostEditPage")
);
const NewsPostListByCategoryPage = lazy(() =>
  import("../Backend/Pages/News/NewsPost/NewsPostListByCategoryPage")
);
const NewsPostViewMostPage = lazy(() =>
  import("../Backend/Pages/News/NewsPost/NewsPostViewMostPage")
);

// SHOP
const ShopDashboardPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopDashboardPage")
);
const ShopCategoryListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopCategory/ShopCategoryListPage")
);
const ShopTypeCategoryLevel2Page = lazy(() =>
  import("../Backend/Pages/Shop/ShopCategory/ShopTypeCategoryLevel2/ShopTypeCategoryLevel2Page")
);
const ShopProductPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopProduct/ShopProductPage")
);
const ShopProductTrashPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopProduct/ShopProductTrashPage")
);
const ShopProductEditPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopProduct/ShopProductEditPage")
);
const ShopOrderPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopOrder/ShopOrderPage")
);
const ShopSaleStaffListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopSaleStaff/ShopSaleStaffListPage")
);
const ShopOurCustomerListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopOurCustomer/ShopOurCustomerListPage")
);
const ShopIntroSlideListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopIntroSlide/ShopIntroSlideListPage")
);
const ShopCertificateListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopCertificate/ShopCertificateListPage")
);
const ShopContactPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopContact/ShopContactPage")
);
const ShopCustomerReviewPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopCustomerReview/ShopCustomerReviewPage")
);
const ShopSupportInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopSupportInfo/ShopSupportInfoListPage")
);
const ShopQuickSupportInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopQuickSupportInfo/ShopQuickSupportInfoListPage")
);
const ShopHotlineInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopHotlineInfo/ShopHotlineInfoListPage")
);
const ShopSocialInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopSocialInfo/ShopSocialInfoListPage")
);
const ShopPaymentInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopPaymentInfo/ShopPaymentInfoListPage")
);
const ShopBankPaymentInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopBankPaymentInfo/ShopBankPaymentInfoListPage")
);
const ShopFooter1LinkInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopFooter1LinkInfo/ShopFooter1LinkInfoListPage")
);
const ShopFooter2LinkInfoListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopFooter2LinkInfo/ShopFooter2LinkInfoListPage")
);
const ShopStaticPageListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopStaticPage/ShopStaticPageListPage")
);
const ShopCareServicePage = lazy(() =>
  import("../Backend/Pages/Shop/ShopCareService/ShopCareServicePage")
);
const ShopMyCustomerPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopMyCustomer/ShopMyCustomerPage")
);
// const ShopProductOfCustomerListPage = lazy(() =>
//   import("../Backend/Pages/Shop/ShopProductOfCustomer/ShopProductOfCustomerListPage")
// );
const ShopQuestionAndAnswerPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopQuestionAndAnswer/ShopQuestionAndAnswerPage")
);
const ShopOurTeamPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopOurTeam/ShopOurTeamPage")
);
const ShopGalleryVideoCategoryPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopGalleryVideo/ShopGalleryVideoCategoryPage")
);
const ShopGalleryVideoPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopGalleryVideo/ShopGalleryVideoPage")
);
const ShopGalleryImageCategoryPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopGalleryImage/ShopGalleryImageCategoryPage")
);
const ShopGalleryImagePage = lazy(() =>
  import("../Backend/Pages/Shop/ShopGalleryImage/ShopGalleryImagePage")
);
const ShopProductCommentListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopProductComment/ShopProductCommentListPage")
);
const ShopProductViewMostListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopProduct/ShopProductViewMostListPage")
);
const ShopOurPartnerListPage = lazy(() =>
  import("../Backend/Pages/Shop/ShopOurPartner/ShopOurPartnerListPage")
);
const ShopConsultingServicePage = lazy(() =>
  import("../Backend/Pages/Shop/ShopConsultingService/ShopConsultingServicePage")
);


// COLLABORATOR
const CollaboratorDashboardPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorDashboardPage")
);
const CollaboratorPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorList/CollaboratorPage")
);
const CollaboratorProgramPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorProgram/CollaboratorProgramPage")
);
const CollaboratorIntroduceCustomerPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorIntroduceCustomer/CollaboratorIntroduceCustomerPage")
);
const CollaboratorReviewPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorReview/CollaboratorReviewPage")
);

const CollaboratorDesignerPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorDesigner/CollaboratorDesignerPage")
);
const CollaboratorPaymentPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorPayment/CollaboratorPaymentPage")
);
// 
const CollaboratorMeDashboardPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorMeDashboardPage")
);
const CollaboratorMePage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorMe/CollaboratorMePage")
);
const CollaboratorMePaymentHistoryPage = lazy(() =>
  import("../Backend/Pages/Collaborator/CollaboratorMe/CollaboratorMePaymentHistoryPage")
);

// 
const routers = [
  {
    path: myRouters.DASHBOARD_URL,
    name: i18n.t("Dashboard"),
    component: DashboardPage,
    layout: BackendLayout,
    exact: true,
    allows: [ROLES.ADMIN ,ROLES.MANAGER, ROLES.MEMBER],
  },
  {
    path: myRouters.PROFILE_PAGE_URL,
    name: i18n.t("Profile"),
    component: ProfilePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MANAGER, ROLES.MEMBER],
  },
  {
    path: myRouters.HISTORY_LOG_PAGE_URL,
    name: i18n.t("History log"),
    component: HistoryLogPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MANAGER],
  },
  {
    path: myRouters.CHANGE_PASSWORD_PAGE_URL,
    name: "Thay đổi mật khẩu",
    component: ChangePasswordPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MANAGER],
  },
  // BLOG
  {
    path: myRouters.BLOG_DASHBOARD_PAGE_URL,
    name: "Blog - Bảng điều khiển",
    component: BlogDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.BLOG_CATEGORY_LIST_PAGE_URL,
    name: "Danh mục blog",
    component: BlogCategoryListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.BLOG_POST_LIST_PAGE_URL,
    name: "Danh sách bài đăng",
    component: BlogPostListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.BLOG_POST_EDIT_PAGE_URL + "/:postBlogId",
    name: "Cập nhật bài đăng",
    component: BlogPostEditPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.BLOG_POST_LIST_BY_CATEGORY_PAGE_URL + "/:categoryLevel2Id/:categoryName",
    name: "Danh sách bài đăng bởi danh mục",
    component: BlogPostListByCategoryPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.BLOG_POST_LIST_VIEW_MOST_PAGE_URL,
    name: "Bài đăng Xem nhiều nhất",
    component: BlogPostViewMostPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.BLOG_POST_COMMENT_LIST_PAGE_URL,
    name: "Bình luận bài đăng",
    component: BlogPostCommentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },

  // NEWS
  {
    path: myRouters.NEWS_DASHBOARD_PAGE_URL,
    name: "Tin tức - Bảng điều khiển",
    component: NewsDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.NEWS_CATEGORY_LIST_PAGE_URL,
    name: "Danh mục tin tức",
    component: NewsCategoryListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.NEWS_POST_LIST_PAGE_URL,
    name: "Danh sách tin tức",
    component: NewsPostListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.NEWS_POST_EDIT_PAGE_URL + "/:postNewsId",
    name: "Cập nhật bản tin",
    component: NewsPostEditPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.NEWS_POST_LIST_BY_CATEGORY_PAGE_URL + "/:categoryLevel2Id/:categoryName",
    name: "Danh sách bảng Tin bởi danh mục",
    component: NewsPostListByCategoryPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.NEWS_POST_LIST_VIEW_MOST_PAGE_URL,
    name: "Bảng Tin Xem nhiều nhất",
    component: NewsPostViewMostPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },

  // SHOP
  {
    path: myRouters.SHOP_DASHBOARD_PAGE_URL,
    name: "Shop - Bảng điều khiển",
    component: ShopDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_PRODUCT_COMMENT_LIST_PAGE_URL,
    name: "Bình luận sản phẩm",
    component: ShopProductCommentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_PRODUCT_LIST_PAGE_URL,
    name: "Danh sách sản phẩm",
    component: ShopProductPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_PRODUCT_DELETED_LIST_PAGE_URL,
    name: "Danh sách sản phẩm đã XÓA",
    component: ShopProductTrashPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_PRODUCT_EDIT_PAGE_URL + "/:productId",
    name: "Cập nhật thông tin SP",
    component: ShopProductEditPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_CATEGORY_LIST_PAGE_URL,
    name: "Danh mục sản phẩm",
    component: ShopCategoryListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_TYPE_CATEGORY_LEVEL2_PAGE_URL,
    name: "Kiểu danh mục sản phẩm mức 2",
    component: ShopTypeCategoryLevel2Page,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_ORDER_PAGE_URL,
    name: "Đơn hàng",
    component: ShopOrderPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_SALE_STAFF_LIST_PAGE_URL,
    name: "Nhân viên bán hàng",
    component: ShopSaleStaffListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_OUR_CUSTOMER_LIST_PAGE_URL,
    name: "Khách hàng của chúng tôi",
    component: ShopOurCustomerListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_INTRO_SLIDE_LIST_PAGE_URL,
    name: "Slide giới thiệu",
    component: ShopIntroSlideListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_CERTIFICATE_LIST_PAGE_URL,
    name: "Chứng chỉ, bằng cấp",
    component: ShopCertificateListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_CONTACT_LIST_PAGE_URL,
    name: "Khách hàng liên hệ",
    component: ShopContactPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_CUSTOMER_REVIEW_LIST_PAGE_URL,
    name: "Khách hàng review",
    component: ShopCustomerReviewPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_SUPPORT_INFO_LIST_PAGE_URL,
    name: "Thông tin hỗ trợ",
    component: ShopSupportInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_QUICK_SUPPORT_INFO_LIST_PAGE_URL,
    name: "Thông tin hỗ trợ NHANH",
    component: ShopQuickSupportInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_HOTLINE_INFO_LIST_PAGE_URL,
    name: "Hotline hỗ trợ",
    component: ShopHotlineInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_SOCIAL_INFO_LIST_PAGE_URL,
    name: "Social hỗ trợ",
    component: ShopSocialInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_PAYMENT_INFO_LIST_PAGE_URL,
    name: "Thanh toán hỗ trợ",
    component: ShopPaymentInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_BANK_PAYMENT_INFO_LIST_PAGE_URL,
    name: "Ngân hàng thanh toán",
    component: ShopBankPaymentInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_FOOTER1_LINK_INFO_LIST_PAGE_URL,
    name: "Liên kết footer 1",
    component: ShopFooter1LinkInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_FOOTER2_LINK_INFO_LIST_PAGE_URL,
    name: "Liên kết footer 2",
    component: ShopFooter2LinkInfoListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_STATIC_PAGE_LIST_PAGE_URL,
    name: "Trang tĩnh",
    component: ShopStaticPageListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_CARE_SERVICE_LIST_PAGE_URL,
    name: "Dịch vụ chăm sóc",
    component: ShopCareServicePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_MY_CUSTOMER_PAGE_URL,
    name: "Khách hàng mua hàng",
    component: ShopMyCustomerPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_QUESTION_AND_ANSWER_PAGE_URL,
    name: "Hỏi-Đáp",
    component: ShopQuestionAndAnswerPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_OUR_TEAM_PAGE_URL,
    name: "Đội ngũ nhân viên",
    component: ShopOurTeamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_GALLERY_VIDEO_CATEGORY_PAGE_URL,
    name: "Gallery Video DM",
    component: ShopGalleryVideoCategoryPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_GALLERY_VIDEO_PAGE_URL,
    name: "Gallery Video DS",
    component: ShopGalleryVideoPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_GALLERY_IMAGE_CATEGORY_PAGE_URL,
    name: "Gallery Image DM",
    component: ShopGalleryImageCategoryPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_GALLERY_IMAGE_PAGE_URL,
    name: "Gallery Image DS",
    component: ShopGalleryImagePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_OUR_PARTNER_LIST_PAGE_URL,
    name: "Đối tác của chúng tôi",
    component: ShopOurPartnerListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_CONSULTING_SERVICE_LIST_PAGE_URL,
    name: "Chuỗi giá trị KH",
    component: ShopConsultingServicePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SHOP_PRODUCT_VIEWS_MOST_PAGE_URL,
    name: "Lượt xem SP phổ biến",
    component: ShopProductViewMostListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  // {
  //   path: myRouters.SHOP_PRODUCT_OF_CUSTOMER_LIST_PAGE_URL,
  //   name: "Bàn giao SP cho KH",
  //   component: ShopProductOfCustomerListPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.ADMIN],
  // },

  // COLLABORATOR
  {
    path: myRouters.COLLABORATOR_DASHBOARD_PAGE_URL,
    name: "Cộng tác viên - Bảng điều khiển",
    component: CollaboratorDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.MANAGER],
  },  
  {
    path: myRouters.COLLABORATOR_PAYMENT_PAGE_URL,
    name: "Thanh toán cho CTV",
    component: CollaboratorPaymentPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COLLABORATOR_PROGRAM_PAGE_URL,
    name: "Chương trình CTV",
    component: CollaboratorProgramPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COLLABORATOR_REVIEW_PAGE_URL,
    name: "CTV Review",
    component: CollaboratorReviewPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COLLABORATOR_ME_INCOME_PAGE_URL,
    name: "Thu nhập hàng tháng",
    component: CollaboratorMePaymentHistoryPage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
  },
  {
    path: myRouters.COLLABORATOR_INTRODUCE_CUSTOMER_PAGE_URL,
    name: "Khách được giới thiệu",
    component: CollaboratorIntroduceCustomerPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COLLABORATOR_LIST_PAGE_URL,
    name: "Khách hàng CTV",
    component: CollaboratorPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COLLABORATOR_DESIGNER_PAGE_URL,
    name: "Khách hàng KTS, DESIGNER",
    component: CollaboratorDesignerPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  // ===
  {
    path: myRouters.COLLABORATOR_ME_DASHBOARD_PAGE_URL,
    name: "Bảng điều khiển CTV của tôi",
    component: CollaboratorMeDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
  }, 
  {
    path: myRouters.COLLABORATOR_ME_PAGE_URL,
    name: "Thông tin CTV của tôi",
    component: CollaboratorMePage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
  }, 


  // Authentication
  {
    path: myRouters.NO_PERMISSION_URL,
    name: "Bạn không được phép ở trang này!",
    component: NotAuthorizationPage,
    layout: BackendLayout,    
  },
  {
    path:myRouters.WELCOME_URL,
    name: "CHÚC MỘT NGÀY TỐT LÀNH!",
    component: WelcomePage,
    layout: BackendLayout,    
  },
];

export default routers;
